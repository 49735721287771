import { Button } from "@components/Button";
import { Footer } from "@components/crud/Footer";
import { Loader } from "@components/crud/Loader";
import { CloseIcon } from "@components/Icons";
import { Box, styled, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import {
  AdminLiveStreamStreamIdClipPostBodyShareType,
  useAdminLiveStreamStreamIdClipPost,
  useAdminLiveStreamStreamIdClipTagGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import React, { useEffect, useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import colors from "theme/colors";

const TIME_OPTIONS = [
  {
    label: "30 sec",
    value: 30
  },
  {
    label: "1 min",
    value: 60
  },
  {
    label: "2 min",
    value: 120
  },
  {
    label: "3 min",
    value: 180
  }
];
const StyledBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: "50%",
  left: "50%",
  marginTop: "30px",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  maxWidth: "750px",
  height: "480px",
  zIndex: 9999999,
  overflow: "hidden",
  borderRadius: "16px",
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  },
  ".play-pause-icon button": {
    background: "#000",
    opacity: 0.5
  }
}));
const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: colors.info.main,
  borderRadius: "16px 16px 0px 0px"
});
const StyledHeader = styled(Box)({
  fontSize: "20px",
  color: "#000",
  fontWeight: 700
});
const StyledChip = styled("div")({
  minWidth: "fit-content",
  borderRadius: "99px",
  padding: "6px 12px",
  fontSize: "14px",
  fontWeight: 600,
  background: "#F3F4F7",
  cursor: "pointer"
});
const StyledButton = styled("div")({
  button: {
    border: "2px solid !important",
    borderColor: `${colors.info.main} !important`
  }
});

export const ClipLivestream = ({
  onClose,
  streamDetails
}: {
  onClose: (details) => void;
  streamDetails: { streamId: string; time: number };
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const [permissions, setPermissions] = useState({ post: false });
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<number>(60);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { data: presetTags, isLoading } = useAdminLiveStreamStreamIdClipTagGet(
    streamDetails.streamId
  );

  const { mutate: clip, isLoading: isClipping } =
    useAdminLiveStreamStreamIdClipPost();

  const clipLivestream = (
    type: AdminLiveStreamStreamIdClipPostBodyShareType
  ) => {
    clip(
      {
        streamId: streamDetails.streamId,
        data: {
          position: Math.floor(streamDetails.time),
          duration: selectedTimeFrame,
          tags: selectedTags,
          shareType: type
        }
      },
      {
        onSuccess: () => {
          enqueueSnackbar({
            variant: "success",
            message: "Clip created successfully"
          });
          onClose(streamDetails);
        },
        onError: () => {
          enqueueSnackbar({
            variant: "error",
            message: "Failed to create clip"
          });
        }
      }
    );
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const post = await checkPermission("organization.post", "ON");
      setPermissions({
        post
      });
    };
    fetchPermissions();
  }, []);

  const presetTagsOptions = useMemo(() => {
    if (presetTags?.data && presetTags?.data)
      return presetTags?.data.map((tag) => ({
        label: tag.name!,
        value: tag.presetTagId
      }));
  }, [presetTags]);

  return (
    <StyledBox>
      <StyledBoxHeader>
        <Grid
          container
          style={{
            padding: "18px 20px 20px 32px",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Grid>
            <Typography
              style={{
                fontSize: "20px",
                color: "#fff",
                fontStyle: "normal",
                lineHeight: "20px",
                fontWeight: "600"
              }}
            >
              Clip Live Stream
            </Typography>
          </Grid>
          <Grid>
            <CloseIcon
              data-testid="post-comment-close-icon"
              style={{ cursor: "pointer" }}
              onClick={onClose}
            />
          </Grid>
        </Grid>
      </StyledBoxHeader>
      <div
        style={{
          minHeight: "415px",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Loader isLoading={isLoading}>
          <div
            style={{
              padding: "40px 20px",
              maxHeight: "350px",
              overflow: "auto"
            }}
          >
            <StyledHeader>Time Frame</StyledHeader>
            <Grid container gap="16px" marginTop="12px">
              {TIME_OPTIONS.map((option, index) => (
                <div key={index}>
                  <StyledChip
                    key={index}
                    onClick={() => setSelectedTimeFrame(option.value)}
                    style={{
                      background:
                        selectedTimeFrame === option.value
                          ? colors.info.main
                          : "#F3F4F7",
                      color:
                        selectedTimeFrame === option.value ? "#fff" : "#000"
                    }}
                  >
                    {option.label}
                  </StyledChip>
                </div>
              ))}
            </Grid>
            {presetTags?.data && presetTags?.data.length > 0 && (
              <>
                <StyledHeader style={{ marginTop: "20px" }}>Tag</StyledHeader>
                <Grid container gap="16px" marginTop="12px">
                  {presetTagsOptions &&
                    presetTagsOptions.map((option, index) => (
                      <div key={index}>
                        <StyledChip
                          key={index}
                          onClick={() => {
                            if (selectedTags.includes(option.value as string)) {
                              setSelectedTags(
                                selectedTags.filter(
                                  (tag) => tag !== option.value
                                )
                              );
                            } else {
                              setSelectedTags([
                                ...selectedTags,
                                option.value as string
                              ]);
                            }
                          }}
                          style={{
                            background: selectedTags.includes(
                              option.value as string
                            )
                              ? colors.info.main
                              : "#F3F4F7",
                            color: selectedTags.includes(option.value as string)
                              ? "#fff"
                              : "#000"
                          }}
                        >
                          {option.label}
                        </StyledChip>
                      </div>
                    ))}
                </Grid>
              </>
            )}
          </div>
        </Loader>
        <Footer
          additionalBtns={[
            ...(permissions.post
              ? [
                  <StyledButton key={Math.random().toString()}>
                    <Button
                      variant="admin-secondary"
                      type="button"
                      onClick={() => clipLivestream("POST")}
                      isLoading={isClipping}
                      disabled={isClipping}
                    >
                      Save & Post to Feed
                    </Button>
                  </StyledButton>
                ]
              : []),
            <Button
              variant="admin-primary"
              type="button"
              onClick={() => clipLivestream("LIBRARY")}
              isLoading={isClipping}
              disabled={isClipping}
              key={Math.random().toString()}
            >
              Save to My Library
            </Button>
          ]}
        />
      </div>
    </StyledBox>
  );
};
