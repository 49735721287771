import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { hasPermission } from "@services/Casbin";
import { getOrders } from "@services/Network";
import {
  ModelOrder,
  useAdminCrmOrderIdResendEmail,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { Grid, IconButton, Typography } from "@mui/material";
import {
  ContentCopyOutlined,
  Edit,
  InfoOutlined,
  Visibility
} from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { ToolTip } from "@components/ToolTip";
import GoverningBodyIcon from "@assets/icons/governingBody.svg";
import formatFullName from "@utils/formatFullName";
import { CRM_ACCOUNT_CATEGORIES, CRM_ACCOUNT_TYPES } from "@utils/constants";
import { formatCurrency } from "@utils/formatCurrency";
import { FullName } from "@utils/types";
import { enqueueSnackbar } from "notistack";
import { EmailResendIcon } from "@components/Icons";
import { getCostPerAthlete } from "@utils/GetCostPerAthlete";
import { Tier } from "@pages/settings/CRMSetting";

const IconStyle = {
  height: "20px",
  width: "20px"
};

const formatDateForDisplay = (dateStr) => {
  const inputDate = new Date(dateStr);
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const year = inputDate.getFullYear();
  // Create the formatted date string in "MM/DD/YYYY" format
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const Orders = () => {
  const navigate = useNavigate();
  const [refreshKey] = useState(0);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false,
    resendApproval: false
  });
  const { data: settings, isLoading: isLoading } = useConfigGet();
  const [pricingTier, setPricingTier] = useState<Tier[]>([]);
  useEffect(() => {
    if (settings && settings.data) {
      const priceTiersList = (settings.data.find(
        (item) => item.key === "crm.order.pricing-tier"
      )?.value || []) as Tier[];
      setPricingTier(priceTiersList);
    }
  }, [settings]);

  const onEdit = (order) => navigate(`/crm/orders/${order.orderId}/edit`);
  const onView = (order) => navigate(`/crm/orders/${order.orderId}`);

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.orders", "ADD");
      const edit = await checkPermission("crm.orders", "EDIT");
      const view = await checkPermission("crm.orders", "VIEW");
      const resendApproval = await checkPermission(
        "crm.orders-resend-approval",
        "ON"
      );
      const permission = {
        create,
        edit,
        view,
        resendApproval
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const copyToClipboard = (text, type) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        enqueueSnackbar(`${type} copied to clipboard`, {
          variant: "success"
        });
      });
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        const successful = document.execCommand("copy");
        if (successful)
          enqueueSnackbar(`${type} copied to clipboard`, {
            variant: "success"
          });
      } catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
      }
      document.body.removeChild(textarea);
    }
  };

  const mutation = useAdminCrmOrderIdResendEmail();

  const ORDERS_COLUMNS: GridColDef<ModelOrder>[] = [
    {
      headerName: "Actions",
      field: "action",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 0", display: "flex" }}>
            {permissions.view && (
              <IconButton onClick={() => onView(params.row)}>
                <ToolTip title="View Order" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {permissions.edit && (
              <IconButton onClick={() => onEdit(params.row)}>
                <ToolTip title="Edit Order" placement="top">
                  <Edit style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
            {params.row.status === "PENDING" && permissions.resendApproval && (
              <Loader isLoading={mutation.isLoading}>
                <IconButton
                  onClick={() => {
                    mutation.mutate({ orderId: params.row.orderId });
                  }}
                >
                  <ToolTip title="Click to Resent Email" placement="top">
                    <EmailResendIcon style={IconStyle} />
                  </ToolTip>
                </IconButton>
              </Loader>
            )}
          </div>
        );
      }
    },
    {
      headerName: "Order ID",
      field: "orderId",
      minWidth: 150,
      flex: 1,
      renderHeader: () => <div style={{ paddingLeft: "3px" }}>Order ID</div>,
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <span>{params.row.orderId?.substring(0, 5)}</span>
            <ToolTip title="Copy Order ID to Clipboard" placement="top">
              <IconButton
                onClick={() => copyToClipboard(params.row.orderId, "Order Id")}
              >
                <ContentCopyOutlined fontSize="small" />
              </IconButton>
            </ToolTip>
          </div>
        );
      }
    },
    {
      headerName: "Order Date",
      field: "orderDate",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => formatDateForDisplay(row.date)
    },
    {
      headerName: "Account",
      field: "account",
      minWidth: 175,
      flex: 1,
      renderCell: (params) => {
        if (params.row.account?.parentId) {
          const parent = params.row.account.parent;
          if (parent)
            return (
              <ToolTip
                title={
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        cursor: "pointer"
                      }}
                    >
                      <Typography color="#007AFF">{parent?.name}</Typography>
                      <IconButton
                        onClick={() =>
                          navigate(`/crm/accounts/${parent.accountId}`)
                        }
                      >
                        <LaunchIcon
                          style={{
                            color: "#007AFF",
                            height: "14px",
                            width: "14px"
                          }}
                        />
                      </IconButton>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px"
                      }}
                    >
                      <Typography color="#64748B" variant="body2">
                        {`${CRM_ACCOUNT_CATEGORIES.find(
                          (c) => c.value === parent.category
                        )?.label} • ${CRM_ACCOUNT_TYPES.find(
                          (c) => c.value === parent.type
                        )?.label}`}
                      </Typography>
                      <Typography color="#64748B" variant="body2">
                        {parent.officeAddress}
                      </Typography>
                    </div>
                  </div>
                }
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <span>{params.row.account?.name}</span>
                  <span>
                    <img src={GoverningBodyIcon} />
                  </span>
                </div>
              </ToolTip>
            );
        }
        return <div>{params.row.account?.name}</div>;
      }
    },
    {
      headerName: "Status",
      field: "status",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        [
          {
            label: "Complete",
            value: "APPROVED"
          },
          {
            label: "Pending",
            value: "PENDING"
          }
        ].find((t) => t.value === row.status)?.label || ""
    },
    {
      headerName: "Estimated Amount",
      field: "amount",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Grid container spacing="10px">
            <Grid item width="50%">
              {formatCurrency(params.row.yearlyAmount!)}
            </Grid>
            <Grid item>
              <ToolTip
                title={
                  <>
                    <span>
                      No. of Yearly Athlete Registrations:
                      {params.row.numberOfAthletes}
                    </span>
                    <br />
                    <span>{`$ ${
                      params.row.opportunity
                        ? params.row.opportunity?.approvedPricePerAthlete
                          ? params.row.opportunity?.approvedPricePerAthlete
                          : getCostPerAthlete(
                              params.row.opportunity.numberOfAthletes!,
                              pricingTier
                            )
                        : getCostPerAthlete(
                            params.row.numberOfAthletes!,
                            pricingTier
                          )
                    } per Athlete, per Registration`}</span>
                  </>
                }
                placement="top"
              >
                <InfoOutlined />
              </ToolTip>
            </Grid>
          </Grid>
        );
      }
    },

    {
      headerName: "Contact",
      field: "contact",
      minWidth: 150,
      valueGetter: ({ row }) =>
        row.contact ? formatFullName(row.contact as FullName) : "",
      flex: 1
    },
    {
      headerName: "Order Submitted By",
      field: "orderSubmittedBy",
      minWidth: 200,
      flex: 1,
      valueGetter: ({ row }) =>
        row.submittedBy?.person ? formatFullName(row.submittedBy.person) : ""
    }
  ];

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: [
      {
        label: "Orders Approved",
        value: "APPROVED"
      },
      {
        label: "Orders Pending Approval",
        value: "PENDING"
      }
    ]
  };

  return (
    <>
      <Loader isLoading={isLoadingPermissions || isLoading}>
        <TableView
          title="Orders"
          useGet={getOrders}
          columns={ORDERS_COLUMNS}
          getRowId={(row) => row.orderId}
          filterConfig={filterConfig}
          defaultSort={[{ field: "name", sort: "asc" }]}
          onAdd={undefined}
          isDeleteDisabled={() => true}
          refreshKey={refreshKey}
          getRowHeight={() => "auto"}
          hasActionColumn={false}
          pinnedColumns={{ left: ["action", "name"] }}
        />
      </Loader>
    </>
  );
};
