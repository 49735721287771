import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FilterTreeSearchComponent } from "@components/FilterTreeSearchComponent";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { Grid } from "@mui/material";
import {
  AdminFeaturePostPutRequest,
  useAdminFeatureEpicGet,
  useAdminFeatureFeatureIdGet,
  useAdminFeatureFeatureIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { cleanObject } from "@utils/cleanObject";
import { FEATURE_PLATFORMS_OPTIONS } from "@utils/constants";
import { enqueueSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

export const FeatureEdit = () => {
  const navigate = useNavigate();
  const { featureId } = useParams();

  const form = useForm({
    mode: "onBlur"
  });
  const [platforms, setPlatforms] = useState<string[]>([]);
  const { data: data, isLoading: isLoading } = useAdminFeatureFeatureIdGet(
    featureId!
  );
  const {
    control,
    getValues,
    setValue,
    formState: { isValid },
    reset
  } = form;

  useEffect(() => {
    if (data) {
      const feature = data.data;
      const defaultValues = {
        name: feature.name,
        parentId: feature.parentId,
        platforms: feature.platforms,
        isActive: feature.isActive,
        iosEpicId: feature.iosEpicId,
        andEpicId: feature.andEpicId,
        webEpicId: feature.webEpicId,
        devOpsEpicId: feature.devOpsEpicId,
        apiEpicId: feature.apiEpicId
      };
      setPlatforms(feature.platforms || []);
      reset(defaultValues);
    }
  }, [data]);
  const { mutate: save, isLoading: isSaving } = useAdminFeatureFeatureIdPut();
  const onSave = () => {
    const values = getValues();
    values.webEpicId = values.webEpicId != "" ? values.webEpicId : null;
    values.iosEpicId = values.iosEpicId != "" ? values.iosEpicId : null;
    values.andEpicId = values.andEpicId != "" ? values.andEpicId : null;
    values.devOpsEpicId =
      values.devOpsEpicId != "" ? values.devOpsEpicId : null;
    values.apiEpicId = values.apiEpicId != "" ? values.apiEpicId : null;

    save(
      {
        data: cleanObject(values) as AdminFeaturePostPutRequest,
        featureId: featureId!
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Feature edited successfully!", {
            variant: "success"
          });

          navigate(`/features/${featureId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to edit Feature!", {
            variant: "error"
          });
        }
      }
    );
  };
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const { data: jiraIssues } = useAdminFeatureEpicGet();
  const [epics, setEpics] = useState<string[]>([]);

  useEffect(() => {
    if (jiraIssues?.data) {
      setEpics(jiraIssues.data.map((d) => d.key));
    }
  }, [jiraIssues]);

  const validateEpic = (val) => {
    return !val || epics.includes(val);
  };

  return (
    <Loader isLoading={isLoading}>
      <Container>
        <Toolbar title="Edit Feature" />
        <Form>
          <Grid container direction="column" spacing="25px">
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={6} data-testid="feature-name">
                <FormInput
                  name="name"
                  control={control}
                  label="Feature Name"
                  onChange={(e) => {
                    setValue("name", capitalizeEveryWord(e.target.value));
                  }}
                  required
                  rules={{
                    required: "Feature Name Is Required"
                  }}
                  type="text"
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={6}>
                <FormCheckbox
                  key="isActive"
                  control={control}
                  name="isActive"
                  label="Is Active"
                  labelPadding="4px 0"
                  labelTypographyProps={{
                    variant: "body1"
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={6}>
                <FilterTreeSearchComponent
                  placeHolder=" "
                  currentFeatureId={featureId}
                  disableSelected
                  selectedValue={getValues().parentId}
                  label="Parent Feature"
                  onFilterChange={(val) => {
                    setValue("parentId", val, { shouldDirty: true });
                  }}
                  onClearFilter={() => {
                    setValue("parentId", null, { shouldDirty: true });
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container direction="row" spacing="24px">
              <Grid item xs={6} data-testid="feature-platforms">
                <FormMultiSelect
                  name="platforms"
                  value="platforms"
                  control={control}
                  label="Platforms"
                  options={FEATURE_PLATFORMS_OPTIONS}
                  required
                  rules={{
                    required: "Platforms Is Required"
                  }}
                  onChange={(e) => {
                    setPlatforms(e.target.value);
                  }}
                  onRemove={(val) => {
                    setPlatforms(platforms.filter((p) => p !== val));
                  }}
                />
              </Grid>
            </Grid>

            {platforms.map((platform) => (
              <Grid
                item
                xs={6}
                width="50%"
                data-testid={`${platform}-jira-epicId`}
                key={platform}
              >
                <FormInput
                  name={`${
                    platform.toLowerCase() === "dev"
                      ? "devOps"
                      : platform.toLowerCase()
                  }EpicId`}
                  label={`${platform} Jira Epic ID`}
                  type="text"
                  capitalize
                  rules={{
                    validate: (v) => validateEpic(v) || "Epic Id Not Found"
                  }}
                  control={control}
                />
              </Grid>
            ))}
          </Grid>
        </Form>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={() => {
            onSave();
          }}
          isDisabled={!isValid || isSaving}
          isLoading={isLoading || isSaving}
        />
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => navigate("/features")}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
      </Container>
    </Loader>
  );
};
