import { useRef, useEffect } from "react";
import { Typography, styled } from "@mui/material";
import { formatCurrency } from "@utils/formatCurrency";
import { CRM_OPPORTUNITY_STAGES } from "@utils/constants";
import { useNavigate } from "react-router-dom";

const OpportunityBox = styled("div")`
  padding: 12px;
  background: #f3f4f7;
  border-radius: 4px;
  margin-top: 16px;
`;

const StyledLink = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #007aff;
  cursor: pointer;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FlexBox = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #666666;
  margin-top: 4px;
`;

const StyledProgress = styled("div")`
  margin-top: 8px;
  display: flex;
  align-items: center;
  background-color: #f3f4f7;
  overflow: hidden;
  position: relative;

  .progress-stage {
    position: relative !important;
    padding: 4px 13px 4px 15px;
    text-align: center;
    white-space: nowrap;
    position: relative;
    flex: 1;
    background: #fff;
    span {
      margin-left: 10px;
    }
  }

  .progress-stage::before {
    z-index: 1;
    content: "";
    position: absolute;
    right: -11px;
    top: -2px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 17px 0 19px 12px;
    border-color: transparent transparent transparent #e5e5e5;
  }

  .progress-stage::after {
    z-index: 2;
    content: "";
    position: absolute;
    right: -8px;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 15px 0 17px 10px;
    border-color: transparent transparent transparent #fff;
  }

  .progress-stage.current::after {
    border-color: transparent transparent transparent #1abc9c;
  }

  .progress-stage.failed::after {
    border-color: transparent transparent transparent #a11212;
  }

  .progress-stage.completed {
    background-color: #ffffff;
    color: #000;
  }

  .progress-stage.current {
    background-color: #1abc9c;
    color: #fff;
  }
  .progress-stage.failed {
    background-color: #a11212;
    color: #fff;
  }
`;

const dateFormat = (isoString) => {
  const date = new Date(isoString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const OpportunityDetails = ({ details }: { details: any }) => {
  const navigate = useNavigate();
  const progressRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateScrollPosition = () => {
      if (progressRef.current) {
        const containerWidth = progressRef.current.offsetWidth;
        const stageWidth = 100;
        const currentStageIndex = CRM_OPPORTUNITY_STAGES.findIndex(
          (stage) => stage.value === details.stage
        );

        const halfContainerWidth = containerWidth / 2;
        const centerPositionOfCurrentStage =
          stageWidth * currentStageIndex + stageWidth / 2;
        const scrollPosition =
          centerPositionOfCurrentStage - halfContainerWidth;

        progressRef.current.scrollLeft = Math.max(0, scrollPosition);
      }
    };

    updateScrollPosition();
    window.addEventListener("resize", updateScrollPosition);
    return () => window.removeEventListener("resize", updateScrollPosition);
  }, [details.stage]);

  return (
    <OpportunityBox>
      <StyledLink
        onClick={() =>
          navigate(
            `/crm/opportunities/${details.opportunityId}?tab=Opportunity+Details`
          )
        }
      >
        {details.name}
      </StyledLink>
      <FlexBox>
        <span>Close Date: {dateFormat(details.closeDate)}</span>
        <span>{details.type}</span>
      </FlexBox>
      <FlexBox>
        <span>
          {details.noOfAthletes} Athletes @{" "}
          {formatCurrency(details.costPerAthlete)} per athlete per year ={" "}
          {formatCurrency(details.noOfAthletes * details.costPerAthlete)}
        </span>
      </FlexBox>
      <StyledProgress className="progress-container" ref={progressRef}>
        {CRM_OPPORTUNITY_STAGES.filter(
          (stage) =>
            stage.value !== "CLOSED_LOST" && stage.value !== "CLOSED_WON"
        )
          .concat(
            details.stage != "CLOSED_LOST"
              ? [{ value: "CLOSED_WON", label: "Closed Won" }]
              : [{ value: "CLOSED_LOST", label: "Closed Lost" }]
          )
          .map((stage) => {
            const isCurrent = stage.value === details.stage;
            const isCompleted = CRM_OPPORTUNITY_STAGES.findIndex(
              (s) => s.value === details.stage
            );
            const isFailed =
              stage.value === "CLOSED_LOST" && details.stage === "CLOSED_LOST";
            return (
              <div
                key={stage.value}
                className={`progress-stage ${
                  isFailed
                    ? "failed"
                    : isCurrent
                    ? "current"
                    : isCompleted >= 0 && !isFailed
                    ? "completed"
                    : ""
                }`}
              >
                <span>{stage.label}</span>
              </div>
            );
          })}
      </StyledProgress>
      {details.stage === "CLOSED_LOST" && (
        <FlexBox style={{ marginTop: "10px" }}>
          <span>Close Lost Reason: {details.notes}</span>
        </FlexBox>
      )}
    </OpportunityBox>
  );
};
