import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Form } from "@components/crud/Form";
import { Footer } from "@components/crud/Footer";
import { OpportunityDetailsForm } from "./OpportunityDetailsForm";
import {
  ContactResponse,
  getAdminCrmAccountAccountIdContact,
  ModelOpportunity,
  useAdminCrmOpportunityOpportunityIdGet,
  useAdminCrmOpportunityOpportunityIdPut,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import formatFullName from "@utils/formatFullName";
import { enqueueSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import { Tier } from "@pages/settings/CRMSetting";
import { getCostPerAthlete } from "@utils/GetCostPerAthlete";
import { OpportunityClosedLostModal } from "./OpportunityClosedLostModal";

export const OpportunityEdit = () => {
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });
  const {
    reset,
    getValues,
    formState: { isDirty, isValid }
  } = form;
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const edit = await checkPermission("crm.opportunities", "EDIT");
      if (!edit) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const [opportunityOwner, setOpportunityOwner] = useState("");
  const [lead, setLead] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      details: ContactResponse;
    }[]
  >([]);
  const [pricingTier, setPricingTier] = useState<Tier[]>([]);

  const { data: settings, isLoading: isLoadingConfig } = useConfigGet();
  const { data: opportunitiesData, isLoading: isLoading } =
    useAdminCrmOpportunityOpportunityIdGet(opportunityId!);
  const [customCostOfAthlete, setCustomCostOfAthlete] = useState<
    number | undefined
  >(undefined);
  const [approvedCostOfAthlete, setApprovedCostOfAthlete] = useState<
    number | undefined
  >(undefined);
  const [discountWithinRange, setDiscountWithinRange] = useState(false);
  const [orderApprovalNeeded, setOrderApprovalNeeded] = useState(false);
  useEffect(() => {
    if (settings && settings.data) {
      const priceTiersList = (settings.data.find(
        (item) => item.key === "crm.order.pricing-tier"
      )?.value || []) as Tier[];
      setPricingTier(priceTiersList);
    }
  }, [settings]);
  useEffect(() => {
    if (opportunitiesData?.data) {
      const opportunity = opportunitiesData.data as ModelOpportunity & {
        approvedPricePerAthlete: number;
      };
      reset({
        createdBy: formatFullName(opportunity.createdBy?.person),
        createdAt: new Date(opportunity.createdAt!),
        type: opportunity.type,
        name: opportunity.name,
        noOfAthletes: opportunity.numberOfAthletes,
        stage: opportunity.stage,
        costOfAthletesPeryear: `$ ${
          opportunity.discountedPricePerAthlete
            ? opportunity.discountedPricePerAthlete
            : getCostPerAthlete(opportunity.numberOfAthletes!, pricingTier)
        } Per Athlete, Per Registration`,
        amount: opportunity.amount,
        closeDate: new Date(opportunity.closeDate!),
        opportunityOwner: opportunity.owner?.personId,
        account: {
          name: opportunity.account?.name || "",
          parent: opportunity.account?.parent?.name || "",
          category: opportunity.account?.category,
          type: opportunity.account?.type,
          officeAddress: opportunity.account?.officeAddress,
          email: opportunity.account?.email,
          website: opportunity.account?.website,
          sportsOffered:
            opportunity.account?.sports?.map((s) => s.sportId) || [],
          noOfAthletes: opportunity.account?.numberOfAthletes,
          aeOwner: opportunity.account?.aeOwner?.person
            ? formatFullName(opportunity.account?.aeOwner?.person)
            : ""
        },
        contact: {
          firstName: opportunity.contact?.firstName,
          lastName: opportunity.contact?.lastName,
          personalEmail: opportunity.contact?.email,
          homeAddress: opportunity.contact?.location,
          phoneType: "MOB",
          phone: opportunity.contact?.phone,
          whatsappNumber: opportunity.contact?.whatsappNumber
        },
        lead: {
          name: opportunity.leads?.[0]?.name || "",
          source: opportunity.leads?.[0]?.source?.type || "",
          status: opportunity.leads?.[0]?.status || "",
          owner: formatFullName(opportunity.leads?.[0]?.owner?.person)
        }
      });
      setCustomCostOfAthlete(opportunity.discountedPricePerAthlete);
      setOrderApprovalNeeded(opportunity.approvals?.[0]?.status === "PENDING");
      setApprovedCostOfAthlete(opportunity.approvedPricePerAthlete);
      setSelectedAccount(opportunity.accountId || "");
      setLead(opportunity.leads?.[0]?.leadId || "");
      setOpportunityOwner(opportunity.ownerId || "");
      if (opportunity.stage === "CLOSED_WON") setIsClosedWon(true);
    }
  }, [opportunitiesData, pricingTier]);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const { mutate: save, isLoading: isSaving } =
    useAdminCrmOpportunityOpportunityIdPut();

  const isSaveAndAddOrder = () => {
    return (
      isClosedWon &&
      !orderApprovalNeeded &&
      (customCostOfAthlete
        ? customCostOfAthlete === approvedCostOfAthlete || discountWithinRange
        : true)
    );
  };
  const onSave = () => {
    const data = {
      name: getValues().name!,
      type: getValues().type!,
      accountId: selectedAccount,
      contactId: selectedContact,
      ownerId: opportunityOwner,
      stage: getValues().stage!,
      numberOfAthletes: parseInt(getValues().noOfAthletes)!,
      amount: parseFloat(parseFloat(getValues().amount).toFixed(2))!,
      closeDate: new Date(getValues().closeDate),
      createdById: opportunitiesData?.data?.createdById || "",
      ...(lead && {
        leadId: lead!
      }),
      ...(customCostOfAthlete && {
        discountedPricePerAthlete: customCostOfAthlete
      }),
      ...(rejectionNotes && {
        notes: rejectionNotes
      })
    };
    save(
      {
        opportunityId: opportunityId!,
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Opportunity Created successfully!", {
            variant: "success"
          });
          if (!isSaveAndAddOrder()) navigate("/crm/opportunities");
          else navigate(`/crm/orders/create?opportunityId=${opportunityId}`);
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Opportunity!", {
            variant: "error"
          });
        }
      }
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount && opportunitiesData?.data) {
        try {
          const data = await getAdminCrmAccountAccountIdContact(
            selectedAccount
          );
          setContacts(
            data.data.map((c) => ({
              label: `${c?.contact?.firstName} ${c?.contact?.lastName}`,
              value: c.contactId as string,
              isPrimary: !!c?.isPrimary,
              details: c?.contact as ContactResponse
            }))
          );
          if (opportunitiesData.data.accountId !== selectedAccount) {
            const primaryOrFirstContact =
              data.data.find((c) => c?.isPrimary)?.contactId ||
              data.data?.[0]?.contactId;

            setSelectedContact(primaryOrFirstContact || "");
          } else {
            setSelectedContact(opportunitiesData.data.contactId!);
          }
        } catch (error) {
          setContacts([]);
        }
      } else setSelectedContact("");
    };
    fetchData();
  }, [selectedAccount, opportunitiesData]);

  const [isClosedWon, setIsClosedWon] = useState(false);
  const [isClosedLost, setClosedLost] = useState(false);
  const [showClosedLostDialog, setShowClosedLostDialog] = useState(false);
  const [rejectionNotes, setRejectionNotes] = useState("");
  return (
    <Container>
      <Toolbar title="Edit Opportunity" />
      <Loader isLoading={isLoading || isLoadingConfig}>
        <Form>
          <OpportunityDetailsForm
            form={form}
            opportunityOwner={opportunityOwner}
            setOpportunityOwner={setOpportunityOwner}
            lead={lead}
            setLead={setLead}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            selectedContact={selectedContact}
            setSelectedContact={setSelectedContact}
            contacts={contacts}
            setContacts={setContacts}
            setClosedWon={(val) => setIsClosedWon(val)}
            customCost={customCostOfAthlete}
            setCustomCost={setCustomCostOfAthlete}
            discountWithinRange={discountWithinRange}
            setDiscountWithinRange={setDiscountWithinRange}
            setOrderApprovalNeeded={setOrderApprovalNeeded}
            setClosedLost={setClosedLost}
          />
        </Form>
      </Loader>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          if (
            orderApprovalNeeded ||
            (customCostOfAthlete &&
              customCostOfAthlete !== approvedCostOfAthlete &&
              !discountWithinRange)
          ) {
            setOpenWarningModal(true);
          } else if (isClosedLost) {
            setShowClosedLostDialog(true);
          } else onSave();
        }}
        saveBtnLabel={isSaveAndAddOrder() ? "Save & Add Order" : "Save"}
        isDisabled={!isDirty || !isValid}
        isLoading={isLoading || isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/crm/opportunities")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      <ConfirmationDialog
        title="Approval Required"
        body={
          !orderApprovalNeeded
            ? "Your custom pricing provided for this opportunity will require approval by a SportsGravy supervisor. Are you sure you would like to submit this for approval?"
            : "Your opportunity will require approval by a SportsGravy supervisor due to the size of the estimated yearly amount. Are you sure you would like to submit this for approval?"
        }
        open={openWarningModal}
        close={() => setOpenWarningModal(false)}
        onCancel={() => setOpenWarningModal(false)}
        onConfirm={() => onSave()}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      <OpportunityClosedLostModal
        open={showClosedLostDialog}
        handleClose={() => {
          setShowClosedLostDialog(false);
        }}
        handleReject={() => {
          onSave();
        }}
        opportunityName={opportunitiesData?.data?.name || ""}
        opportunityOwner={formatFullName(
          opportunitiesData?.data?.owner?.person
        )}
        rejectionNotes={rejectionNotes}
        setRejectionNotes={setRejectionNotes}
      />
    </Container>
  );
};
