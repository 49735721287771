import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import {
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  styled,
  Typography
} from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { useEffect, useState } from "react";
import { CloseIcon } from "@components/Icons";
import { QuickAddContactForm } from "../components/QuickAddContactForm";
import { AccountDetailsForm } from "../components/AccountDetailsForm";
import { ContactDetailsSubForm } from "../components/ContactDetailsSubForm";
import { FormDatePicker } from "@components/FormDatePicker";
import formatFullName from "@utils/formatFullName";
import { QuickAddAccount } from "../accounts/components/QuickAddAccount";
import { Loader } from "@components/crud/Loader";
import { useParams } from "react-router-dom";

const MainContainer = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow: "auto",
  maxHeight: "90%",
  backgroundColor: "white",
  borderRadius: 16,
  "&:focus-visible": {
    outline: "transparent"
  }
});

const Header = styled("div")({
  width: 1000,
  height: 60,
  position: "sticky", // Make the header sticky
  top: 0, // Stick the header to the top of the container
  zIndex: 1,
  backgroundColor: "#2B337A",
  borderTopLeftRadius: 16,
  borderTopRightRadius: 16,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  paddingLeft: 16,
  paddingRight: 16
});

const HeaderText = styled("span")({
  fontSize: 18,
  color: "white"
});

export const OrderDetailsForm = ({
  disabled,
  form,
  selectedAccount,
  setSelectedAccount,
  selectedContact,
  setSelectedContact,
  contacts,
  setContacts
}: {
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any, any>;
  selectedAccount;
  setSelectedAccount;
  selectedContact;
  setSelectedContact;
  contacts;
  setContacts;
}) => {
  const { control, setValue, getValues } = form;
  const { orderId } = useParams();
  useEffect(() => {
    if (selectedContact) {
      const contact = contacts.find((c) => c.value === selectedContact);
      if (contact) {
        setValue(
          "contact",
          {
            name: formatFullName(contact.details),
            personalEmail: contact.details.email,
            homeAddress: contact.details.location,
            phoneType: "MOB",
            phone: contact.details.phone,
            whatsappNumber: contact.details.whatsappNumber
          },
          {
            shouldDirty: true,
            shouldValidate: true
          }
        );
      }
    } else {
      setValue(
        "contact",
        {
          name: "",
          personalEmail: "",
          homeAddress: "",
          phoneType: "MOB",
          phone: "",
          whatsappNumber: ""
        },
        {
          shouldDirty: true,
          shouldValidate: true
        }
      );
    }
  }, [selectedContact, contacts]);

  const [showAddContactModal, setShowAddContactModal] = useState(false);

  const [showQuickAddAccount, setShowQuickAddAccount] = useState(false);

  const [currencySymbol, setCurrencySymbol] = useState("$");
  useEffect(() => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    });
    setCurrencySymbol(formatter.formatToParts(1)[0].value);
  }, []);

  return (
    <Loader isLoading={false}>
      <Grid
        data-testid="order-detail-form"
        container
        direction="column"
        spacing="25px"
      >
        {showQuickAddAccount && (
          <QuickAddAccount
            onClose={() => {
              setShowQuickAddAccount(false);
            }}
            onSave={(resp) => {
              setValue("account", {
                name: resp.name,
                parent: resp.parent?.name,
                category: resp.category,
                type: resp.type,
                officeAddress: resp.officeAddress,
                email: resp.email,
                website: resp.website,
                sportsOffered: [],
                noOfAthletes: "",
                aeOwner: ""
              });
              setSelectedAccount(resp.accountId);
              setShowQuickAddAccount(false);
            }}
          />
        )}

        <Modal
          open={showAddContactModal}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <MainContainer>
            <Header>
              <HeaderText>{`Quick Add Contact for ${getValues(
                "account.name"
              )}`}</HeaderText>
              <IconButton
                onClick={() => {
                  setShowAddContactModal(false);
                  //setRefreshKey(refreshKey + 1);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Header>

            {selectedAccount && (
              <QuickAddContactForm
                onClose={() => {
                  setShowAddContactModal(false);
                }}
                onSave={(resp) => {
                  const options = contacts;
                  setContacts([
                    ...options,
                    {
                      label: `${resp.firstName} ${resp.lastName}`,
                      value: resp.contactId,
                      isPrimary: false,
                      details: resp
                    }
                  ]);
                  setSelectedContact(resp.contactId);
                  setShowAddContactModal(false);
                }}
                accountId={selectedAccount}
              />
            )}
          </MainContainer>
        </Modal>

        <Grid item container direction="row" spacing="24px">
          <Grid item xs={6}>
            <FormInput
              name="submittedBy"
              control={control}
              label="Order Submitted By"
              type="text"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FormDatePicker
              name="orderDate"
              control={control}
              label="Order Date"
              disabled
              required
              rules={{ required: "Close Date is required" }}
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing="24px" alignItems="center">
          <Grid item xs={6}>
            <FormInput
              name="product"
              control={control}
              label="Product"
              type="text"
              disabled
              required
              rules={{ required: "Product is required" }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name="orderType"
              control={control}
              label="Order Type"
              type="text"
              disabled
              required
            />
          </Grid>
        </Grid>
        <Grid item marginTop="-20px" marginLeft="50%">
          <Typography
            style={{
              color: "#B3B3B3",
              marginTop: "5px"
            }}
          >
            Note: This subscription order is based on Per Athlete, Per
            Registration
          </Typography>
        </Grid>
        <Grid item container direction="row" spacing="24px">
          <Grid item xs={3}>
            <FormInput
              name="noOfAthletes"
              control={control}
              rules={{
                required: "No. of Yearly Athlete Registrations is required"
              }}
              label="No. of Yearly Athlete Registrations"
              type="number"
              required={true}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              name="costOfAthletesPeryear"
              control={control}
              label={"\u00A0"}
              type="text"
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name="amount"
              control={control}
              rules={{ required: "Amount is required" }}
              label="Estimated Amount"
              type="text"
              required={true}
              disabled
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {currencySymbol}
                  </InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing="24px" alignItems="center">
          <Grid item xs={6}>
            <FormInput
              name="billingFrequency"
              control={control}
              label="Billing Frequency"
              type="text"
              required
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              name="status"
              control={control}
              label="Status"
              options={[
                {
                  label: "Approved",
                  value: "APPROVED"
                },
                {
                  label: "Pending",
                  value: "PENDING"
                }
              ]}
              disabled
              required
              rules={{ required: "Status is required" }}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormInput
            name="notes"
            control={control}
            disabled={disabled}
            label="Notes"
            type="text"
            multiline
            rows={4}
          />
        </Grid>
        <AccountDetailsForm
          disabled
          form={form}
          hideNoOfAthletes
          setSelectedAccount={setSelectedAccount}
          setIsNewAccountSelected={setShowQuickAddAccount}
        />
        <ContactDetailsSubForm
          disabled={disabled}
          form={form}
          contactOptions={contacts}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          setShowAddContactModal={setShowAddContactModal}
          selectedAccount={selectedAccount}
          relatesTo="ORDER"
          relatesToId={orderId as string}
          account={{
            accountId: selectedAccount,
            name: getValues("account.name"),
            type: getValues("account.type"),
            officeAddress: getValues("account.officeAddress")
          }}
        />
      </Grid>
    </Loader>
  );
};
