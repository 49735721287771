import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Toolbar } from "@components/crud/Toolbar";
import React, { SyntheticEvent, useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Loader } from "@components/crud/Loader";
import { Form } from "@components/crud/Form";
import { OpportunityDetailsForm } from "./OpportunityDetailsForm";
import {
  ContactResponse,
  getAdminCrmAccountAccountIdContact,
  ModelContact,
  ModelOpportunityAccount,
  useAdminCrmOpportunityOpportunityIdDelete,
  useAdminCrmOpportunityOpportunityIdGet,
  useConfigGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { AddIcon } from "@components/Icons";
import { Edit } from "@mui/icons-material";
import { Typography } from "@mui/material";
import styled from "styled-components";
import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { AccountDetails } from "../accounts/components/AccountDetails";
import { ContactDetails } from "../accounts/components/ContactDetails";
import { OpportunityDetails } from "../accounts/components/OpportunityDetails";
import Grid from "@mui/system/Unstable_Grid";
import { Activity } from "../activities/Activity";
import formatFullName from "@utils/formatFullName";
import {
  CRM_ACCOUNT_CATEGORIES,
  CRM_OPPORTUNITY_TYPES,
  ORGANIZATION_TYPE_OPTIONS
} from "@utils/constants";
import { hasPermission } from "@services/Casbin";
import { ActivityDetails } from "../accounts/components/ActivityDetails";
import { NoRecords } from "@components/NoRecords";
import { Tier } from "@pages/settings/CRMSetting";
import { getCostPerAthlete } from "@utils/GetCostPerAthlete";
import { DevTool } from "@hookform/devtools";
import { ApprovalHistoryDetails } from "../opportunity-approvals/ApprovalHistoryDetails";

const StyledHeader = styled(Typography)`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #000000;
  opacity: 0.7;
  text-transform: uppercase;
`;

const StyledLink = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #007aff;
  cursor: pointer;
`;

const TabHeader = ({
  header,
  buttonText,
  onClick
}: {
  header: string;
  buttonText?: string;
  onClick?: () => void;
}) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <StyledHeader>{header}</StyledHeader>
      {buttonText && onClick && (
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          {buttonText.includes("Edit") ? (
            <Edit style={{ height: "16px", width: "16px", color: "#007aff" }} />
          ) : (
            <AddIcon
              style={{ height: "16px", width: "16px", color: "#007aff" }}
            />
          )}
          <StyledLink onClick={onClick}>{buttonText}</StyledLink>
        </div>
      )}
    </div>
  );
};

export const OpportunityView = () => {
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const form = useForm({
    mode: "onBlur"
  });
  const { reset } = form;

  const [opportunittyPermissions, setOpportunittyPermissions] = useState({
    create: false,
    edit: false,
    delete: false
  });
  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const opView = await checkPermission("crm.opportunities", "VIEW");
      const opEdit = await checkPermission("crm.opportunities", "EDIT");
      const opDelete = await checkPermission("crm.opportunities", "DELETE");
      setOpportunittyPermissions({
        create: opView,
        edit: opEdit,
        delete: opDelete
      });
      if (!opView) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const [opportunityOwner, setOpportunityOwner] = useState("");
  const [lead, setLead] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [selectedContactId, setSelectedContactId] = useState<string | null>(
    null
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      details: ContactResponse;
    }[]
  >([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [pricingTier, setPricingTier] = useState<Tier[]>([]);
  const { data: settings, isLoading: isLoadingConfig } = useConfigGet();
  const { data: opportunitiesData, isLoading: isLoading } =
    useAdminCrmOpportunityOpportunityIdGet(opportunityId!);

  useEffect(() => {
    if (settings && settings.data) {
      const priceTiersList = (settings.data.find(
        (item) => item.key === "crm.order.pricing-tier"
      )?.value || []) as Tier[];
      setPricingTier(priceTiersList);
    }
  }, [settings]);

  useEffect(() => {
    if (opportunitiesData?.data) {
      const opportunity = opportunitiesData.data;
      reset({
        createdBy: formatFullName(opportunity?.createdBy?.person),
        createdAt: new Date(opportunity.createdAt!),
        type: opportunity.type,
        name: opportunity.name,
        noOfAthletes: opportunity.numberOfAthletes,
        stage: opportunity.stage,
        costOfAthletesPeryear: `$ ${
          opportunity.discountedPricePerAthlete
            ? opportunity.discountedPricePerAthlete
            : getCostPerAthlete(opportunity.numberOfAthletes!, pricingTier)
        } Per Athlete, Per Registration`,
        amount: opportunity.amount,
        closeDate: new Date(opportunity.closeDate!),
        opportunityOwner: opportunity.owner?.personId,
        account: {
          name: opportunity.account?.name || "",
          parent: opportunity.account?.parent?.name || "",
          category: opportunity.account?.category,
          type: opportunity.account?.type,
          officeAddress: opportunity.account?.officeAddress,
          email: opportunity.account?.email,
          website: opportunity.account?.website,
          sportsOffered:
            opportunity.account?.sports?.map((s) => s.sportId) || [],
          noOfAthletes: opportunity.account?.numberOfAthletes,
          aeOwner: opportunity.account?.aeOwner?.person
            ? formatFullName(opportunity.account?.aeOwner?.person)
            : ""
        },
        contact: {
          firstName: opportunity.contact?.firstName,
          lastName: opportunity.contact?.lastName,
          personalEmail: opportunity.contact?.email,
          homeAddress: opportunity.contact?.location,
          phoneType: "MOB",
          phone: opportunity.contact?.phone,
          whatsappNumber: opportunity.contact?.whatsappNumber
        },
        lead: {
          name: opportunity.leads?.[0]?.name || "",
          source: opportunity.leads?.[0]?.source?.type || "",
          status: opportunity.leads?.[0]?.status || "",
          owner: formatFullName(opportunity.leads?.[0]?.owner?.person)
        }
      });
      setSelectedAccount(opportunity.accountId || "");

      setLead(opportunity.leads?.[0]?.leadId || "");
      setOpportunityOwner(opportunity.ownerId || "");
    }
  }, [opportunitiesData, pricingTier]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount && opportunitiesData?.data) {
        try {
          const data = await getAdminCrmAccountAccountIdContact(
            selectedAccount
          );
          setContacts(
            data.data.map((c) => ({
              label: `${c.contact.firstName} ${c.contact.lastName}`,
              value: c.contactId as string,
              isPrimary: !!c.isPrimary,
              details: c.contact as ContactResponse
            }))
          );
          if (opportunitiesData.data.accountId !== selectedAccount) {
            const primaryOrFirstContact =
              data.data.find((c) => c.isPrimary)?.contactId ||
              data.data?.[0]?.contactId;

            setSelectedContact(primaryOrFirstContact || "");
          } else {
            setSelectedContact(opportunitiesData.data.contactId!);
          }
        } catch (error) {
          setContacts([]);
        }
      } else setSelectedContact("");
    };
    fetchData();
  }, [selectedAccount, opportunitiesData]);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminCrmOpportunityOpportunityIdDelete();

  const onOpportunityDelete = async () => {
    try {
      await deleteAsync({ opportunityId: opportunityId as string });
      enqueueSnackbar("Opportunity Deleted successfully", {
        variant: "success"
      });
      setOpenDeleteDialog(false);
      navigate("/crm/opportunities");
    } catch (e) {
      enqueueSnackbar(
        "Something went wrong! Unable to delete the Opportunity.",
        {
          variant: "error"
        }
      );
      setOpenDeleteDialog(false);
    }
  };
  const [tab, setTab] = useState(
    searchParams.get("tab") || "Opportunity Dashboard"
  );
  const onTabChange = (event: SyntheticEvent, value: unknown) => {
    setTab(value as string);
    setSearchParams({ tab: value as string });
  };
  useEffect(() => {
    if (searchParams.get("tab")) {
      setTab(searchParams.get("tab") as string);
    }
  }, [searchParams]);
  const getTabs = () => {
    return ["Opportunity Dashboard", "Opportunity Details", "Activities"];
  };

  useEffect(() => {
    if (opportunitiesData?.data && opportunitiesData.data.contact) {
      setSelectedContactId(
        opportunitiesData.data?.contact?.contactId as string
      );
    }
  }, [opportunitiesData]);

  return (
    <Container>
      <Toolbar
        title="View Opportunity"
        backBtnClick={() => {
          navigate("/crm/opportunities");
        }}
        {...(opportunittyPermissions.edit &&
          opportunitiesData?.data?.stage !== "CLOSED_WON" &&
          (opportunitiesData?.data?.orders?.length || 0) === 0 && {
            editBtnClick: () =>
              navigate(`/crm/opportunities/${opportunityId}/edit`)
          })}
        {...(opportunittyPermissions.delete && {
          deleteBtnClick: () => setOpenDeleteDialog(true)
        })}
        tabs={{
          tabs: [
            ...getTabs(),
            ...((opportunitiesData?.data?.approvals?.length || 0) > 0
              ? ["Approval History"]
              : [])
          ],
          onTabChange: onTabChange,
          activeTab: tab
        }}
      />
      <Loader isLoading={isDeleting || isLoading || isLoadingConfig}>
        {opportunitiesData?.data && (
          <>
            {tab === "Opportunity Dashboard" && (
              <Form>
                <Grid container spacing={3}>
                  <Grid md={6} xs={12} container direction="column">
                    <Grid xs={12}>
                      <TabHeader
                        header="Account Details"
                        buttonText="Edit Account"
                        onClick={() =>
                          navigate(
                            `/crm/accounts/${opportunitiesData?.data?.accountId}/edit`
                          )
                        }
                      />
                      <HeaderUnderLine width="100%" />
                      <AccountDetails
                        details={{
                          name: opportunitiesData?.data?.account?.name,
                          type:
                            CRM_ACCOUNT_CATEGORIES.find(
                              (a) =>
                                a.value ===
                                opportunitiesData?.data?.account?.category
                            )?.label || "",
                          orgType:
                            ORGANIZATION_TYPE_OPTIONS.find(
                              (o) =>
                                o.value ===
                                opportunitiesData?.data?.account?.type
                            )?.label || "",
                          officeAddress:
                            opportunitiesData?.data?.account?.officeAddress,
                          billingAddress:
                            opportunitiesData?.data?.account?.billingAddress,
                          shippingAddress:
                            opportunitiesData?.data?.account?.shippingAddress,
                          phone: opportunitiesData?.data?.account?.phone,
                          fax: opportunitiesData?.data?.account?.fax,
                          email: opportunitiesData?.data?.account?.email,
                          website: opportunitiesData?.data?.account?.website,
                          sports: [],
                          noOfAthletes:
                            opportunitiesData?.data?.account?.numberOfAthletes,
                          bdrOwner: opportunitiesData?.data?.account?.bdrOwner
                            ?.person
                            ? formatFullName(
                                opportunitiesData?.data?.account?.bdrOwner
                                  ?.person
                              )
                            : "",
                          aeOwner: opportunitiesData?.data?.account?.aeOwner
                            ?.person
                            ? formatFullName(
                                opportunitiesData?.data?.account?.aeOwner
                                  ?.person
                              )
                            : "",
                          csmOwner: opportunitiesData?.data?.account?.csmOwner
                            ?.person
                            ? formatFullName(
                                opportunitiesData?.data?.account?.csmOwner
                                  ?.person
                              )
                            : "",
                          parent: {
                            name:
                              opportunitiesData?.data?.account?.parent?.name ||
                              ""
                          },
                          accountId: opportunitiesData?.data?.accountId
                        }}
                        hideNoOfAthletes
                        hideParent
                        hideSportsOffered
                      />
                    </Grid>
                  </Grid>
                  <Grid md={6} xs={12} container direction="column">
                    {opportunitiesData?.data.contact && (
                      <Grid xs={12}>
                        <TabHeader
                          header="Contact Details"
                          buttonText="Edit Contact"
                          onClick={() => {
                            navigate(`/crm/contacts/${selectedContactId}/edit`);
                          }}
                        />
                        <HeaderUnderLine width="100%" />
                        <ContactDetails
                          account={
                            opportunitiesData?.data
                              ?.account as ModelOpportunityAccount
                          }
                          contactDetails={[
                            {
                              contact: opportunitiesData?.data
                                ?.contact as ModelContact,
                              isPrimary:
                                opportunitiesData?.data.contact?.accounts?.find(
                                  (a) =>
                                    a.accountId ===
                                    opportunitiesData?.data?.accountId
                                )?.isPrimary || false
                            }
                          ]}
                          contactSelected={setSelectedContactId}
                          relatesTo="OPPORTUNITY"
                          relatesToId={opportunityId}
                        />
                      </Grid>
                    )}
                    <Grid xs={12}>
                      <TabHeader header="Opportunity Details" />
                      <HeaderUnderLine width="100%" />
                      <div style={{ marginTop: "24px" }}>
                        <OpportunityDetails
                          details={{
                            opportunityId:
                              opportunitiesData?.data?.opportunityId,
                            name: opportunitiesData?.data?.name,
                            closeDate: opportunitiesData?.data?.closeDate,
                            type:
                              CRM_OPPORTUNITY_TYPES.find(
                                (o) => o.value === opportunitiesData?.data?.type
                              )?.label || "",
                            noOfAthletes:
                              opportunitiesData?.data?.numberOfAthletes,
                            costPerAthlete: opportunitiesData?.data
                              ?.numberOfAthletes
                              ? opportunitiesData?.data
                                  ?.discountedPricePerAthlete
                                ? opportunitiesData.data
                                    .discountedPricePerAthlete
                                : getCostPerAthlete(
                                    opportunitiesData?.data?.numberOfAthletes,
                                    pricingTier
                                  )
                              : 0,
                            stage: opportunitiesData?.data?.stage,
                            notes: opportunitiesData?.data?.notes
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  {opportunitiesData?.data?.activities.length > 0 && (
                    <Grid xs={12}>
                      <TabHeader
                        header="Activity Details"
                        buttonText="Add Activity"
                        onClick={() =>
                          navigate(
                            `/crm/activities/create?opportunityId=${opportunityId}`
                          )
                        }
                      />
                      <HeaderUnderLine width="100%" />
                      <Grid xs={12} marginTop="25px">
                        <Activity
                          showToolbar={false}
                          hideFooter
                          hideRelatedTo
                          useOfflineTable
                          addLabel="Add Activity Related To This Opportunity"
                          activities={opportunitiesData?.data?.activities}
                          onClickAdd={() => {
                            navigate(
                              `/crm/activities/create?opportunityId=${opportunityId}`
                            );
                          }}
                          tablePadding={1}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Form>
            )}
            {tab === "Opportunity Details" && (
              <Form>
                <OpportunityDetailsForm
                  form={form}
                  opportunityOwner={opportunityOwner}
                  setOpportunityOwner={setOpportunityOwner}
                  lead={lead}
                  disabled
                  setLead={setLead}
                  selectedAccount={selectedAccount}
                  setSelectedAccount={setSelectedAccount}
                  selectedContact={selectedContact}
                  setSelectedContact={setSelectedContact}
                  contacts={contacts}
                  setContacts={setContacts}
                />
                <DevTool control={form.control} />
              </Form>
            )}

            {tab === "Activities" && (
              <Form>
                {opportunitiesData?.data?.activityChangeLog.length > 0 && (
                  <ActivityDetails
                    details={opportunitiesData?.data?.activityChangeLog}
                  />
                )}
                {opportunitiesData?.data?.activityChangeLog.length === 0 && (
                  <NoRecords title="No Activities Found" />
                )}
              </Form>
            )}
            {tab === "Approval History" && (
              <Form
                style={{
                  paddingTop: "5xpx"
                }}
              >
                <ApprovalHistoryDetails
                  details={opportunitiesData?.data?.approvals}
                />
              </Form>
            )}
          </>
        )}
      </Loader>
      <ConfirmationDialog
        title="Delete Opportunity?"
        body="Are you sure you want to delete this Oppportnity?"
        open={openDeleteDialog}
        close={() => setOpenDeleteDialog(false)}
        onCancel={() => setOpenDeleteDialog(false)}
        onConfirm={() => onOpportunityDelete()}
        cancelBtnText="No"
        confirmBtnText="Yes"
        confirmBtnVariant="admin-error"
        icon="error"
      />
    </Container>
  );
};
