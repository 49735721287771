/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseIcon } from "@components/Icons";
import {
  Box,
  styled,
  Backdrop,
  IconButton,
  Typography,
  Slider
} from "@mui/material";
import {
  ModelStream,
  ModelStreamScore
} from "@sportsgravyengineering/sg-api-react-sdk";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReactPlayer from "react-player";
import { useEffect, useRef, useState } from "react";
import { LoadingSpinner } from "@components/LoadingSpinner";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Forward5Icon from "@mui/icons-material/Forward5";
import Replay5Icon from "@mui/icons-material/Replay5";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import { ClipLivestream } from "./ClipLivestream";
import { organizationAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import { hasPermission } from "@services/Casbin";

type StreamScore = {
  scores: ModelStreamScore[];
  timestamp: number;
};

type IntervalCount = {
  intervalCount: number;
  isHomeTeam: boolean | null;
  timestamp: number;
};

const convertToSeconds = (time: string): number => {
  const [hours, minutes, seconds] = time.split(":").map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

const formatTime = (seconds: number): string => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
};

const StyledBox = styled(Box)(({ theme }) => ({
  marginTop: "50px",
  position: "fixed",
  backgroundColor: "#000",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  maxWidth: "1000px",
  height: "554px",
  zIndex: 9999,
  overflow: "hidden",
  borderRadius: "5px",
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  },
  ".play-pause-icon button": {
    background: "#000",
    opacity: 0.5
  }
}));

const TeamName = styled(Typography)({
  fontWeight: 500,
  fontSize: "13px",
  lineHeight: "16px",
  color: "#fff"
});

const TeamScore = styled(Typography)({
  fontWeight: 700,
  fontSize: "13px",
  lineHeight: "16px",
  color: "#fff"
});

export const CompletedLiveStreamPlayer = ({
  onClose,
  liveStream
}: {
  onClose: () => void;
  liveStream: ModelStream;
}) => {
  const organizationId = useRecoilValue(organizationAtom);
  const [permissions, setPermissions] = useState({ clip: false });
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [scores, setScores] = useState<StreamScore[]>([]);
  const [intervals, setIntervals] = useState<IntervalCount[]>([]);
  const [score, setScore] = useState<StreamScore | undefined>(undefined);
  const [interval, setInterval] = useState<IntervalCount | undefined>(
    undefined
  );
  const [isHome] = useState<boolean>(liveStream.isHomeTeam as boolean);
  const [duration, setDuration] = useState<number>(0);
  const [currentDuration, setCurrentDuration] = useState<number>(0);
  const playerRef = useRef<ReactPlayer>(null);
  const [playing, setPlaying] = useState(true);
  const [isScoreExpanded, setIsScoreExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [muted, setMuted] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const handlePlayPause = () => setPlaying(!playing);
  const toggleScoreExpand = () => setIsScoreExpanded(!isScoreExpanded);
  const [showControls, setShowControls] = useState(true);
  const [clipData, setClipData] = useState<
    { streamId: string; time: number } | undefined
  >(undefined);
  useEffect(() => {
    if (liveStream.metadata && liveStream.metadata?.scores) {
      const modifiedScores = liveStream.metadata?.scores
        .map((score: any) => ({
          ...score,
          timestamp: convertToSeconds(score.timestamp)
        }))
        .sort((a, b) => b.timestamp - a.timestamp);
      setScores(modifiedScores);
    }
    if (liveStream.metadata && liveStream.metadata?.intervals) {
      const modifiedIntervals = liveStream.metadata?.intervals
        .map((interval: any) => ({
          ...interval,
          timestamp: convertToSeconds(interval.timestamp)
        }))
        .sort((a, b) => b.timestamp - a.timestamp);
      setIntervals(modifiedIntervals);
    }
  }, [liveStream]);
  useEffect(() => {
    if (scores.length > 0) {
      const currentScore = scores.find(
        (score) => score.timestamp <= currentDuration
      );
      setScore(currentScore);
    }
    if (intervals.length > 0) {
      const currentInterval = intervals.find(
        (interval) => interval.timestamp <= currentDuration
      );
      setInterval(currentInterval);
    }
  }, [currentDuration]);

  const handleMuteUnmute = () => setMuted(!muted);
  const handleFullscreen = () => {
    const elem = document.getElementById("livestream-player");
    if (elem) {
      if (!isFullscreen && elem.requestFullscreen) {
        elem.requestFullscreen();
      }
      if (isFullscreen && document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };
  useEffect(() => {
    const elem = document.getElementById("livestream-player");
    if (elem) {
      elem.addEventListener("fullscreenchange", () => {
        setIsFullscreen(!!document.fullscreenElement);
      });
    }
  });

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    const seekTo = newValue as number;
    setCurrentDuration(seekTo);
    if (playerRef.current) {
      playerRef.current.seekTo(seekTo, "seconds");
    }
  };

  const seek5Seconds = (forward: boolean) => {
    const newTime = forward
      ? Math.min(duration, currentDuration + 5)
      : Math.max(0, currentDuration - 5);
    setCurrentDuration(newTime);
    if (playerRef.current) {
      playerRef.current.seekTo(newTime, "seconds");
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowRight":
          seek5Seconds(true);
          break;
        case "ArrowLeft":
          seek5Seconds(false);
          break;
        default:
          break;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentDuration, duration]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowControls(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  const handleMouseMove = () => {
    setShowControls(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setShowControls(false);
    }, 3000);
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        organizationId ? "ORGANIZATION" : "SYSTEM",
        organizationId || "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const clip = await checkPermission("live-streaming.clip-stream", "ON");
      setPermissions({
        clip: clip
      });
    };
    fetchPermissions();
  }, []);

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <StyledBox id="livestream-player" onMouseMove={handleMouseMove}>
        {
          <>
            <div
              className="react-player"
              onClick={handlePlayPause}
              style={{ width: "100%", height: "100%" }}
            >
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                  }}
                >
                  <LoadingSpinner size={50} />
                </div>
              )}
              <ReactPlayer
                ref={playerRef}
                playing={playing}
                muted={muted}
                height="100%"
                width="100%"
                url={
                  liveStream.vod?.baseUrl && liveStream.vod?.path
                    ? liveStream.vod?.baseUrl + liveStream.vod?.path
                    : ""
                }
                onStart={() => setIsLoading(false)}
                onBuffer={() => setIsLoading(true)}
                onBufferEnd={() => setIsLoading(false)}
                onEnded={() => setPlaying(false)}
                onDuration={(duration) => setDuration(duration)}
                onProgress={(state) => setCurrentDuration(state.playedSeconds)}
              />
            </div>

            {!isLoading && (
              <div
                className="play-pause-icon"
                style={{
                  opacity: showControls || !playing ? 1 : 0,
                  transition: "opacity 0.6s",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)"
                }}
              >
                {playing && (
                  <IconButton
                    style={{ marginRight: "35px" }}
                    onClick={() => seek5Seconds(false)}
                  >
                    <Replay5Icon
                      sx={{ fill: "#fff", height: "40px", width: "40px" }}
                    />
                  </IconButton>
                )}

                <IconButton onClick={handlePlayPause}>
                  {playing ? (
                    <PauseIcon
                      sx={{ fill: "#fff", height: "50px", width: "50px" }}
                    />
                  ) : (
                    <PlayArrowIcon
                      sx={{ fill: "#fff", height: "50px", width: "50px" }}
                    />
                  )}
                </IconButton>
                {playing && (
                  <IconButton
                    style={{ marginLeft: "35px" }}
                    onClick={() => seek5Seconds(true)}
                  >
                    <Forward5Icon
                      sx={{ fill: "#fff", height: "40px", width: "40px" }}
                    />
                  </IconButton>
                )}
              </div>
            )}
            {(liveStream.contestType == "GAME" ||
              liveStream.contestType == "SCRIMMAGE") &&
              liveStream.scores &&
              liveStream.scores?.length > 0 &&
              scores && (
                <Box
                  sx={{
                    position: "absolute",
                    top: "20px",
                    left: "20px",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    background: "#5f5f5f"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          background: "#E82C2C",
                          width: "100%",
                          padding: "4px 8px",
                          textAlign: "center",
                          fontWeight: "700",
                          height: "30px",
                          borderTopLeftRadius: "8px"
                        }}
                      >
                        {`${
                          liveStream.team?.gender == "MALE"
                            ? liveStream.sport?.organizations?.[0]
                                .maleIntervalAbbreviation
                            : liveStream.sport?.organizations?.[0]
                                .femaleIntervalAbbreviation
                        }${
                          !interval?.intervalCount
                            ? liveStream.intervalCount
                            : interval.intervalCount
                        }`}
                      </Box>
                      <Box
                        sx={{
                          background: "#2B337A",
                          width: "100%",
                          color: "#fff",
                          fontWeight: "700",
                          padding: "4px 8px",
                          textAlign: "center",
                          height: "30px",
                          borderBottomLeftRadius: "8px"
                        }}
                      >
                        {`H ${score?.scores?.[0]?.homeScore || 0} - ${
                          score?.scores?.[0]?.awayScore || 0
                        } A`}
                      </Box>
                    </Box>
                    {isScoreExpanded && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          margin: "0 8px",
                          rowGap: "10px"
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "space-between"
                          }}
                        >
                          <TeamName>
                            {isHome
                              ? liveStream.team?.name
                              : liveStream.opponent}{" "}
                            (H)
                          </TeamName>
                          <TeamScore>
                            {score?.scores?.[0]?.homeScore || 0}
                          </TeamScore>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            justifyContent: "space-between"
                          }}
                        >
                          <TeamName>
                            {!isHome
                              ? liveStream.team?.name
                              : liveStream.opponent}{" "}
                            (A)
                          </TeamName>
                          <TeamScore>
                            {score?.scores?.[0]?.awayScore || 0}
                          </TeamScore>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      background: "#848484",
                      width: "20px",
                      height: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderTopRightRadius: "8px",
                      borderBottomRightRadius: "8px",
                      cursor: "pointer"
                    }}
                    onClick={toggleScoreExpand}
                  >
                    {isScoreExpanded ? (
                      <ChevronLeftIcon
                        sx={{
                          fill: "#fff",
                          height: "20px",
                          width: "120x"
                        }}
                      />
                    ) : (
                      <ChevronRightIcon
                        onClick={toggleScoreExpand}
                        sx={{
                          fill: "#fff",
                          height: "20px",
                          width: "20px"
                        }}
                      />
                    )}
                  </Box>
                </Box>
              )}
            <Box
              className="control-box"
              sx={{
                opacity: showControls ? 1 : 0,
                transition: "opacity 0.6s",
                position: "absolute",
                bottom: "15px",
                padding: "0 30px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px"
                  }}
                >
                  <IconButton
                    onClick={handlePlayPause}
                    style={{ paddingLeft: 0 }}
                  >
                    {!playing ? (
                      <PlayArrowIcon sx={{ fill: "#fff" }} />
                    ) : (
                      <PauseIcon sx={{ fill: "#fff" }} />
                    )}
                  </IconButton>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: 600
                    }}
                  >
                    {formatTime(currentDuration)} / {formatTime(duration)}
                  </Typography>
                </Box>
                <Box>
                  {permissions.clip && (
                    <IconButton
                      onClick={() => {
                        setClipData({
                          streamId: liveStream.streamId as string,
                          time: currentDuration
                        });
                      }}
                      sx={{ color: "#fff" }}
                    >
                      <ContentCutIcon
                        sx={{ fill: "#fff", width: "20px", height: "20px" }}
                      />
                    </IconButton>
                  )}
                  <IconButton onClick={handleMuteUnmute} sx={{ color: "#fff" }}>
                    {muted ? (
                      <VolumeOffIcon sx={{ fill: "#fff" }} />
                    ) : (
                      <VolumeUpIcon sx={{ fill: "#fff" }} />
                    )}
                  </IconButton>
                  <IconButton onClick={handleFullscreen} sx={{ color: "#fff" }}>
                    {isFullscreen ? (
                      <FullscreenExitIcon sx={{ fill: "#fff" }} />
                    ) : (
                      <FullscreenIcon sx={{ fill: "#fff" }} />
                    )}
                  </IconButton>
                </Box>
              </Box>
              <Slider
                value={currentDuration}
                max={duration}
                min={0}
                onChange={handleSliderChange}
                sx={{
                  color: "#e82c2c",
                  "& .MuiSlider-thumb": {
                    backgroundColor: "#e82c2c",
                    height: 14,
                    width: 14,
                    opacity: 0,
                    transition: "opacity 0.3s"
                  },
                  "&:hover .MuiSlider-thumb": {
                    opacity: 1
                  },
                  "& .MuiSlider-track": {
                    height: 4
                  },
                  "& .MuiSlider-rail": {
                    height: 4,
                    color: "#fff"
                  }
                }}
              />
            </Box>
          </>
        }
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: 16,
            right: 16
          }}
        >
          <CloseIcon sx={{ height: "24px", width: "24px" }} />
        </IconButton>
        {clipData && (
          <ClipLivestream
            onClose={() => setClipData(undefined)}
            streamDetails={clipData}
          />
        )}
      </StyledBox>
    </Backdrop>
  );
};
