import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Toolbar } from "@components/crud/Toolbar";
import React, { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Form } from "@components/crud/Form";
import { OpportunityDetailsForm } from "./OpportunityDetailsForm";
import formatFullName from "@utils/formatFullName";
import { profileAtom } from "@recoil/auth";
import { useRecoilValue } from "recoil";
import {
  adminCrmLeadLeadIdGet,
  ContactResponse,
  getAdminCrmAccountAccountId,
  getAdminCrmAccountAccountIdContact,
  ModelPerson,
  useAdminCrmOpportunityPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { enqueueSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";

export const OpportunityCreate = () => {
  const navigate = useNavigate();
  const form = useForm({
    mode: "onBlur",
    shouldUnregister: true
  });

  const {
    reset,
    getValues,
    formState: { isValid }
  } = form;

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.opportunities", "ADD");
      if (!create) navigate("/not-found");
    };
    fetchPermissions();
  }, []);

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [opportunityOwner, setOpportunityOwner] = useState("");
  const [lead, setLead] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedContact, setSelectedContact] = useState("");
  const [customCostOfAthlete, setCustomCostOfAthlete] = useState<
    number | undefined
  >(undefined);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [orderApprovalNeeded, setOrderApprovalNeeded] = useState(false);
  const [discountWithinRange, setDiscountWithinRange] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const leadId = searchParams.get("leadId");
  const accountId = searchParams.get("accountId");

  const [contacts, setContacts] = useState<
    {
      label: string;
      value: string;
      isPrimary: boolean;
      details: ContactResponse;
    }[]
  >([]);

  const userName = formatFullName(
    useRecoilValue(profileAtom)?.person as ModelPerson
  );
  const userId = useRecoilValue(profileAtom)?.userId || "";

  useEffect(() => {
    reset({
      createdBy: userName || "",
      createdAt: new Date(),
      type: "NEW_BUSINESS"
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (leadId) {
        const data = await adminCrmLeadLeadIdGet(leadId);
        if (data.data.accountId) {
          const account = data.data.account;
          const contact = data.data.contact;
          const lead = data.data;
          reset({
            account: {
              name: account?.name || "",
              parent: account?.parent?.name || "",
              category: account?.category,
              type: account?.type,
              officeAddress: account?.officeAddress,
              email: account?.email,
              website: account?.website,
              sportsOffered: account?.sports?.map((sp) => sp.sportId) || [],
              noOfAthletes: account?.numberOfAthletes,
              aeOwner: account?.aeOwner?.person
                ? formatFullName(account?.aeOwner?.person)
                : ""
            },
            contact: {
              firstName: contact?.firstName,
              lastName: contact?.lastName,
              personalEmail: contact?.email,
              homeAddress: contact?.location,
              phoneType: "MOB",
              phone: contact?.phone,
              whatsappNumber: contact?.whatsappNumber
            },
            lead: {
              name: lead.name || "",
              source: lead.source?.type || "",
              status: lead.status,
              owner: formatFullName(lead?.owner?.person)
            },
            createdBy: userName || "",
            createdAt: new Date(),
            type: "NEW_BUSINESS",
            costOfAthletesPeryear: `$ 19.99 per athlete per year`
          });
          setSelectedAccount(data.data.accountId);
          setLead(leadId);
          // setSelectedContact(data.data.contactId);
        }
      }
    };
    fetchData();
  }, [leadId]);

  useEffect(() => {
    const fetchData = async () => {
      if (accountId) {
        const data = await getAdminCrmAccountAccountId(accountId);
        if (data.data.accountId) {
          const account = data.data;
          // const contact = data.data.contact;
          reset({
            account: {
              name: account?.name || "",
              parent: account?.parent?.name || "",
              category: account?.category,
              type: account?.type,
              officeAddress: account?.officeAddress,
              email: account?.email,
              website: account?.website,
              sportsOffered: account?.sports?.map((sp) => sp.sportId) || [],
              noOfAthletes: account?.numberOfAthletes,
              aeOwner: account?.aeOwner?.person
                ? formatFullName(account?.aeOwner?.person)
                : ""
            },
            createdBy: userName || "",
            createdAt: new Date(),
            type: "NEW_BUSINESS",
            costOfAthletesPeryear: `$ 19.99 per athlete per year`
          });
          setSelectedAccount(data.data.accountId);
          // setSelectedContact(data.data.contactId);
        }
      }
    };
    fetchData();
  }, [accountId]);

  const { mutate: save, isLoading: isSaving } = useAdminCrmOpportunityPost();
  const onSave = (resetInstreadOfRoute: boolean) => {
    const data = {
      name: getValues().name!,
      type: getValues().type!,
      accountId: selectedAccount,
      contactId: selectedContact,
      ownerId: opportunityOwner,
      stage: getValues().stage!,
      numberOfAthletes: parseInt(getValues().noOfAthletes)!,
      amount: parseFloat(parseFloat(getValues().amount).toFixed(2))!,
      closeDate: new Date(getValues().closeDate!),
      createdById: userId!,
      ...(lead && {
        leadId: lead!
      }),
      ...(customCostOfAthlete && {
        discountedPricePerAthlete: customCostOfAthlete
      })
    };
    save(
      {
        data: data
      },
      {
        onSuccess: () => {
          enqueueSnackbar("Opportunity Created successfully!", {
            variant: "success"
          });
          if (resetInstreadOfRoute) {
            reset({
              createdBy: userName || "",
              createdAt: new Date(),
              type: "NEW_BUSINESS",
              costOfAthletesPeryear: `$ 19.99 per athlete per year`
            });
            setLead("");
            setOpportunityOwner("");
            setSelectedContact("");
            setSelectedAccount("");
          } else {
            navigate("/crm/opportunities");
          }
        },
        onError: () => {
          enqueueSnackbar("Failed to Create Opportunity!", {
            variant: "error"
          });
        }
      }
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      if (selectedAccount) {
        try {
          const data = await getAdminCrmAccountAccountIdContact(
            selectedAccount
          );
          setContacts(
            data.data.map((c) => ({
              label: `${c?.contact?.firstName} ${c?.contact?.lastName}`,
              value: c.contactId as string,
              isPrimary: !!c?.isPrimary,
              details: c?.contact as ContactResponse
            }))
          );
          const primaryOrFirstContact =
            data.data.find((c) => c?.isPrimary)?.contactId ||
            data.data?.[0]?.contactId;
          setSelectedContact(primaryOrFirstContact || "");
        } catch (error) {
          setContacts([]);
        }
      }
    };
    fetchData();
  }, [selectedAccount]);
  return (
    <Container>
      <Toolbar title="Add Opportunity" />
      <Form>
        <OpportunityDetailsForm
          form={form}
          opportunityOwner={opportunityOwner}
          setOpportunityOwner={setOpportunityOwner}
          selectedAccount={selectedAccount}
          lead={lead}
          setLead={setLead}
          setSelectedAccount={setSelectedAccount}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          contacts={contacts}
          setContacts={setContacts}
          customCost={customCostOfAthlete}
          setCustomCost={setCustomCostOfAthlete}
          discountWithinRange={discountWithinRange}
          setDiscountWithinRange={setDiscountWithinRange}
          setOrderApprovalNeeded={setOrderApprovalNeeded}
        />
      </Form>
      <Footer
        cancelBtnClick={() => setOpenCancelDialog(true)}
        saveBtnClick={() => {
          if (
            orderApprovalNeeded ||
            (customCostOfAthlete && !discountWithinRange)
          ) {
            setOpenWarningModal(true);
          } else onSave(false);
        }}
        saveAndNewBtnClick={() => {
          if (
            orderApprovalNeeded ||
            (customCostOfAthlete && !discountWithinRange)
          ) {
            setOpenWarningModal(true);
          } else onSave(true);
        }}
        isDisabled={!isValid}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        open={openCancelDialog}
        close={() => setOpenCancelDialog(false)}
        onCancel={() => setOpenCancelDialog(false)}
        onConfirm={() => navigate("/crm/opportunities")}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
      <ConfirmationDialog
        title="Approval Required"
        body={
          !orderApprovalNeeded
            ? "Your custom pricing provided for this opportunity will require approval by a SportsGravy supervisor. Are you sure you would like to submit this for approval?"
            : "Your opportunity will require approval by a SportsGravy supervisor due to the size of the estimated yearly amount. Are you sure you would like to submit this for approval?"
        }
        open={openWarningModal}
        close={() => setOpenWarningModal(false)}
        onCancel={() => setOpenWarningModal(false)}
        onConfirm={() => onSave(false)}
        cancelBtnText="Cancel"
        confirmBtnText="Confirm"
      />
    </Container>
  );
};
