import { Loader } from "@components/crud/Loader";
import { TableView } from "@components/TableView";
import { hasPermission } from "@services/Casbin";
import { getOpportunityApprovals } from "@services/Network";
import { ModelOpportunityApproval } from "@sportsgravyengineering/sg-api-react-sdk";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import { IconButton, styled, Typography } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { GridColDef } from "@mui/x-data-grid";
import { ToolTip } from "@components/ToolTip";
import GoverningBodyIcon from "@assets/icons/governingBody.svg";
import formatFullName from "@utils/formatFullName";
import {
  CRM_ACCOUNT_CATEGORIES,
  CRM_ACCOUNT_TYPES,
  CRM_OPPORTUNITY_STAGES,
  CRM_OPPORTUNITY_TYPES
} from "@utils/constants";
import { formatCurrency } from "@utils/formatCurrency";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

const IconStyle = {
  height: "20px",
  width: "20px"
};

const TwoLineText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
`;

const formatDateForDisplay = (dateStr) => {
  const inputDate = new Date(dateStr);
  const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
  const day = inputDate.getDate().toString().padStart(2, "0");
  const year = inputDate.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
};

export const OpportunityApprovals = () => {
  dayjs.extend(timezone);

  const navigate = useNavigate();
  const [refreshKey] = useState(0);
  const [isLoadingPermissions, setPermissionsLoading] = useState(true);
  const [permissions, setPermissions] = useState({
    create: false,
    edit: false,
    view: false
  });
  const onView = (opportunityApproval) =>
    navigate(
      `/crm/opportunities-approvals/${opportunityApproval.opportunityId}`
    );
  const ACCOUNTS_COLUMNS: GridColDef<ModelOpportunityApproval>[] = [
    {
      headerName: "Actions",
      field: "action",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderHeader: () => <div style={{ paddingLeft: "10px" }}>Actions</div>,
      renderCell: (params) => {
        return (
          <div style={{ padding: "20px 0", display: "flex" }}>
            {permissions.view && (
              <IconButton onClick={() => onView(params.row)}>
                <ToolTip title="View Opportunity" placement="top">
                  <Visibility style={IconStyle} />
                </ToolTip>
              </IconButton>
            )}
          </div>
        );
      }
    },
    {
      headerName: "Needs Approval by",
      field: "needsApprovalBy",
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row }) => row.needsApprovalBy?.name || ""
    },
    {
      headerName: "Name",
      field: "name",
      minWidth: 250,
      flex: 1,
      renderHeader: () => <div style={{ paddingLeft: "3px" }}>Name</div>,
      renderCell: (params) => (
        <TwoLineText>{params.row.opportunity?.name}</TwoLineText>
      )
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        CRM_OPPORTUNITY_TYPES.find((t) => t.value === row.opportunity?.type)
          ?.label || ""
    },
    {
      headerName: "Close Date",
      field: "closeDate",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => formatDateForDisplay(row.opportunity?.closeDate)
    },
    {
      headerName: "Stage",
      field: "stage",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        CRM_OPPORTUNITY_STAGES.find((t) => t.value === row.opportunity?.stage)
          ?.label || ""
    },
    {
      headerName: "Amount",
      field: "amount",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => formatCurrency(row.opportunity?.amount)
    },
    {
      headerName: "Account",
      field: "account",
      minWidth: 175,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.opportunity?.account?.parentId) {
          const parent = params.row.opportunity.account.parent;
          return (
            <ToolTip
              title={
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      navigate(`/crm/accounts/${parent?.accountId}`)
                    }
                  >
                    <Typography color="#007AFF">{parent?.name}</Typography>
                    <IconButton>
                      <LaunchIcon
                        style={{
                          color: "#007AFF",
                          height: "14px",
                          width: "14px"
                        }}
                      />
                    </IconButton>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px"
                    }}
                  >
                    <Typography color="#64748B" variant="body2">
                      {`${CRM_ACCOUNT_CATEGORIES.find(
                        (c) => c.value === parent?.category
                      )?.label} • ${CRM_ACCOUNT_TYPES.find(
                        (c) => c.value === parent?.type
                      )?.label}`}
                    </Typography>
                    <Typography color="#64748B" variant="body2">
                      {parent?.officeAddress}
                    </Typography>
                  </div>
                </div>
              }
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <span>{params.row.opportunity?.account?.name}</span>
                <span>
                  <img src={GoverningBodyIcon} />
                </span>
              </div>
            </ToolTip>
          );
        }
        return <div>{params.row.opportunity?.account?.name}</div>;
      }
    },
    {
      headerName: "Contact",
      field: "contact",
      minWidth: 150,
      valueGetter: ({ row }) =>
        row.opportunity?.contact
          ? formatFullName(row.opportunity?.contact)
          : "",
      flex: 1
    },
    {
      headerName: "Opportunity Owner",
      field: "owner",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) =>
        row.opportunity?.owner?.person
          ? formatFullName(row.opportunity?.owner.person)
          : ""
    }
  ];

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "SYSTEM",
        "*",
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const create = await checkPermission("crm.opportunities", "ADD");
      const view = await checkPermission("crm.opportunities", "VIEW");
      const edit = await checkPermission("crm.opportunities", "EDIT");
      const permission = {
        create,
        edit,
        view
      };
      setPermissions(permission);
      setPermissionsLoading(false);
    };
    fetchPermissions();
  }, []);

  const filterConfig = {
    field: "filter",
    placeholderOption: {
      label: "All",
      value: "all"
    },
    options: [
      {
        label: "My Approvals",
        value: "mine"
      }
    ]
  };

  return (
    <>
      <Loader isLoading={isLoadingPermissions}>
        <TableView
          title="Opportunity Approvals"
          useGet={getOpportunityApprovals}
          columns={ACCOUNTS_COLUMNS}
          onView={(row) => onView(row)}
          getRowId={(row) => row.approvalId}
          filterConfig={filterConfig}
          defaultSort={[{ field: "name", sort: "asc" }]}
          onAdd={undefined}
          isDeleteDisabled={() => true}
          refreshKey={refreshKey}
          getRowHeight={() => "auto"}
          hasActionColumn={false}
          pinnedColumns={{ left: ["action", "name"] }}
        />
      </Loader>
    </>
  );
};
